/* eslint-disable @typescript-eslint/indent */
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { SchemaOf, object, string } from 'yup';

import { IUser } from 'api/tables';
import { AsyncButton } from 'components/form/AsyncButton';
import Input from 'components/form/Input';
import { useGlobalUserContext, useLoggedInUser } from 'contexts/user-context/UserContext';
import theme from 'styles/theme';

interface IUpdateEmailModal {
  isOpen: boolean;
  onRequestClose(): void;
  closeModal(): void;
}

interface IUpdateEmailForm {
  currentEmail: string;
  newEmail: string;
  confirmPassword: string;
}

const schema: SchemaOf<IUpdateEmailForm> = object({
  currentEmail: string().required('Current email is required')
    .email('Current email required'),
  newEmail: string().required('New email is required')
    .email('Email address must be valid'),
  confirmPassword: string().required('Please enter your password'),
});

export default function UpdateEmailModal({ isOpen, onRequestClose, closeModal }: IUpdateEmailModal): JSX.Element {
  const { userStateDispatch } = useGlobalUserContext();
  const { user } = useLoggedInUser();
  const formMethods = useForm<IUpdateEmailForm>({
    defaultValues: { currentEmail: user.email },
    resolver: yupResolver(schema),
  });
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const { t } = useTranslation();

  async function onSubmit(emailData: IUpdateEmailForm): Promise<void> {
    try {
      const fetchedUser = await axios.post('/api/auth/login', {
        email: emailData.currentEmail,
        password: emailData.confirmPassword,
      }, {
        validateStatus: (status) => (status >= 200 && status < 300) || status === 401,
      });

      if (fetchedUser.status === 401) {
        setError('currentEmail', {
          type: 'manual',
          message: t('Incorrect e-mail or password. Please try again.'),
        });
        setError('confirmPassword', {
          type: 'manual',
        });
        return;
      }

      const result = await axios.put<IUser>('/api/users', {
        ...emailData,
        email: emailData.newEmail,
      });
      userStateDispatch({ type: 'update', user: result.data });
      toast.success(t('Successfully updated email'));
      window.location.reload();
    } catch (e) {
      toast.error(t('Error updating email'));
      console.error(e);
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Modal id='change-email-modal' ariaHideApp={false} isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete='false'>
          <Input className='current-email' type='text' label={t('Current Email')} {...register('currentEmail')} />
          <Input className='new-email' type='text' label={t('New Email')} {...register('newEmail')} />
          <Input className='confirm-password' type='password' label={t('Enter your password')} {...register('confirmPassword')} />
          <div className='button-container'>
            <AsyncButton resetAfterSubmit formState={formState} type='submit' className='save'>{t('Save')}</AsyncButton>
            <button className='cancel' onClick={closeModal}>{t('Cancel')}</button>
          </div>
        </StyledForm>
      </Modal>
    </FormProvider>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .current-email,
  .new-email,
  .confirm-password {
    width: 90%;
    padding-top: 15px;

    label {
      color: black;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .save {
      height: 51px;
      width: 195px;
      padding: 2px 0 0;
      margin-right: 10px;
    }

    .cancel {
      background: white;
      color: black;
      border: 3px solid ${theme.primary};
      height: 51px;
      width: 195px;
      padding: 2px 0 0;
      margin-left: 10px;
    }
  }
`;

const modalStyles = {
    overlay: {
      backgroundColor: 'none',
    },

    content: {
      width: '484px',
      height: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 14px 54px rgb(112 112 112 / 35%)',
    },
};
