import useAxios from 'axios-hooks';
import { Trans } from 'react-i18next';
import { When } from 'react-if';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import About from './components/About';
import Banner from './components/Banner';
import CommunityTabs from './components/CommunityTabs';
import FollowButton from './components/FollowButton';
import Info from './components/Info';
import { ICommunity } from '../api/tables';
import { AxiosWrapper } from '../components/AxiosWrapper';
import CommunityLogo from 'components/CommunityLogo';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import theme, { device } from 'styles/theme';
import { getMarketingUrl } from 'utils';

export default function CommunityPage(): JSX.Element {
  const { slug } = useParams<{ slug: string }>();
  const { userState: { user } } = useGlobalUserContext();
  const [community] = useAxios<ICommunity>(`/api/communities/slug/${slug}`);

  const link = `${getMarketingUrl()}/faq#comp-l9x6rwy112`;

  return (
    <AxiosWrapper requests={[community]}>
      <Container loggedOut={!user}>
        <Banner className='banner' colour={community.data?.colour || '1768aa'} />
        <section className='top'>
          <div className='logo'>
            <When condition={!!community.data}>
              <CommunityLogo id={community.data?.id || 0} logoUrl={community.data?.logo_url || ''} name={community.data?.name} className='community-page-logo' />
            </When>
          </div>
          <h1>{community.data?.name}</h1>
          <div className='follow-button'>
            <FollowButton community={community.data as ICommunity} />
            <When condition={!!user}>
              <p><Trans>Member vs follower? Read <a href={link} target='_blank' rel='noopener noreferrer'>more</a></Trans></p>
            </When>
          </div>
          <div className='info-about'>
            <Info className='info' community={community.data as ICommunity} />
            <About className='about' community={community.data as ICommunity} />
          </div>
        </section>
        <CommunityTabs />
      </Container>
    </AxiosWrapper>
  );
}

const Container = styled.div<{ loggedOut: boolean }>`
  * {
    font-family: 'Liberation Sans', Inter, sans-serif;
  }

  @media only screen and (min-width: 1200px) {
    width: ${(props) => (props.loggedOut ? '1100px' : 'initial')};
    margin: ${(props) => (props.loggedOut ? '0 auto' : 'initial')};
  }

  h1 {
    font-family: Poppins, Inter, sans-serif;
  }

  .banner {
    margin-bottom: 25px;
  }

  /* ArticleCard.tsx */
  .article-card {
    width: 100%;
  }

  & > .top {
    display: grid;
    grid-template-columns: 212px auto 155px;
    grid-template-rows: 72px auto;
    grid-gap: 41px 35px;
    margin-bottom: 75px;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: end;
      align-self: end;
      width: 185px;
      height: 185px;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

      img {
        max-width: 110px;
        display: block;
      }
    }

    h1 {
      align-self: center;
    }

    .follow-button {
      align-self: center;

      p {
        color: ${theme.text};
        font-size: 12px;
        width: 190px;
        position: relative;
        top: 10px;
        right: 31px;
      }
    }

    .info-about {
      background-color: ${theme.grayBackground};
      width: 100%;
      display: flex;
      grid-row: 2 / 2;
      grid-column: 1 / span 3;
      border-radius: 10px;

      .info {
        width: 212px;
        margin-bottom: -30px;
        position: relative;
        bottom: 15px;
      }

      .about {
        width: calc(100% - 212px);
      }
    }
  }

  ${device.tablet} {
    margin-top: ${(props) => (props.loggedOut ? '-20px' : '-80px')};

    & > .top {
      grid-template-columns: 149px auto;
      grid-template-rows: 44px 36px auto;
      column-gap: auto;
      row-gap: 23px 37px;

      .logo {
        width: 149px;
        height: 149px;
      }

      h1 {
        font-size: 24px;
        grid-row: 2;
        grid-column: 1 / span 2;
      }

      .follow-button {
        justify-self: end;

        button {
          min-width: 135px;
          width: 135px;
          height: 44px;
        }
      }

      .info-about {
        width: 98%;
        display: flex;
        flex-direction: column;
        height: auto;
        grid-column: 1 / span 2;
        grid-row: 3;

        .info {
          margin-bottom: -80px;
        }

        .info,
        .about {
          width: 90%;
          gap: 10px;
        }
      }
    }
  }

`;
