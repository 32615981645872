import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LogoutButton from 'components/LogoutButton';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import Card from 'styles/styled-components/Card';
import theme from 'styles/theme';

interface IMenu {
  className: string;
}

export default function UserMenu({ className }: IMenu): JSX.Element {
  const { t } = useTranslation();
  const { user } = useLoggedInUser();
  const path = window.location.pathname;

  const feedsLink = useMemo(() => {
    if (path === '/feeds' || path === '/follow') return path;
    return '/feeds';
  }, [path]);

  return (
    <Container className={className}>
      <div className='settings'>
        <Link className='link' to={`/profile/${user.id}`}>{t('My Profile')}</Link>
        <Link className='link' to={feedsLink}>{t('Customize sources')}</Link>
      </div>
      <hr />
      <LogoutButton className='logout' />
    </Container>
  );
}

const Container = styled(Card)`
  width: 193px;
  padding: 0;
  overflow: hidden;
  z-index: 3;
  box-shadow: 0 4px 30px 2px #EAEAEA !important;

  hr {
    border: 1.5px solid #F0F6FF;
    background-color: #F0F6FF;
    position: relative;
    width: 157px;
    margin: 0 auto;
  }

  .settings .link {
    color: black;
    padding: 13px 18px;
    display: block;
    text-decoration: none;
    font-weight: 400;

    &:hover {
      color: ${theme.gray};
      cursor: pointer;
    }
  }

  .logout {
    background: transparent;
    text-transform: none;
    color: black;
    padding: 13px 18px;
    width: 100%;
    text-align: left;

    &:hover {
      color: ${theme.gray};
    }
  }
`;
