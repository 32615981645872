import { t } from 'i18next';
import styled from 'styled-components';

import Logo from 'assets/graphics/logo-lightversion.svg';
import theme, { device } from 'styles/theme';
import { getMarketingUrl } from 'utils';

export default function ExternalLinks(): JSX.Element {
  const basePath = getMarketingUrl();

  return (
    <Container>
      <img className='logo-light' src={Logo} />

      <div className='first'>
        <div className='about'><a target='_blank' href={`${basePath}/about`} rel='noreferrer'>{t('About')}</a></div>
        <div className='contact'><a target='_blank' href={`${basePath}/contact`} rel='noreferrer'>{t('Contact')}</a></div>
      </div>

      <div className='second'>
        <div className='privacy-policy'><a target='_blank' href={`${basePath}/privacy-policy`} rel='noreferrer'>{t('Privacy Policy')}</a></div>
        <div className='terms-of-use'><a target='_blank' href={`${basePath}/terms-of-use`} rel='noreferrer'>{t('Terms of Use')}</a></div>
      </div>

      <div className='copyright'>
        <p>{`© ${t('Sosido Networks.')}`}</p>
      </div>
    </Container>
  );
}
const Container = styled.div`
  text-align: center;
  bottom: 5px;
  position: absolute;
  padding: 5px 30px 0 53px;

  .logo-light {
    width: 80px;
    padding: 0;
    margin-bottom: 2px;
  }

  a,
  p {
    font-family: 'Liberation Sans', sans-serif;
    text-decoration: none;
    font-size: 10px;
    font-weight: 200;
    color: ${theme.gray};
  }

  a:visited {
    color: ${theme.gray};
  }

  .first {
    display: flex;
    justify-content: center;

    .about {
      padding-right: 6px;
    }

    .contact {
      padding-left: 6px;
    }

    a:hover {
      color: ${theme.primary};
    }
  }

  .second {
    display: flex;
    margin-bottom: 6px;
    justify-content: center;

    .privacy-policy {
      padding-right: 6px;
    }

    .terms-of-use {
      padding-left: 6px;
    }

    a:hover {
      color: ${theme.primary};
    }
  }

  .copyright {
    margin-bottom: 20px;
  }

  ${device.tablet} {
    left: 0;
    right: 0;
    padding-right: 60px;
  }
`;
