import DOMPurify from 'dompurify';
import parse, { DOMNode, Element } from 'html-react-parser';
import { useMemo } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';

import { IArticleDto } from 'api/dtos/article.dto';
import ArticleHeader from 'articles/ArticleHeader';
import ArticleDescription from 'styles/styled-components/ArticleDescription';
import ArticleTitle from 'styles/styled-components/ArticleTitle';
import Card from 'styles/styled-components/Card';
import { device } from 'styles/theme';

const parseVideo = {
  replace: (domNode: DOMNode) => {
    if (!(domNode instanceof Element)) return;

    let src: string | undefined;
    if (domNode.name === 'figure') {
      const child = (domNode.children as Element[])[0];
      src = child.name === 'a' ? child.attribs.href : undefined;
    }
    // ql-video is a class added by QuillJS. Which is used in Retool
    if (domNode.name === 'iframe' && domNode.attribs.class === 'ql-video') {
      src = domNode.attribs.src;
    }
    if (!src) return;
    // youtu includes youtube and youtu.be
    if (src.includes('youtu') || src.includes('bit.ly')) {
      const isAppleMobile = /(ipad|iphone|ipod)/i.test(navigator.userAgent);
      // extensions sometimes cause autoplay to occur, sandbox fixes this.
      if (isAppleMobile || src.includes('youtu')) {
        return (
          <div className='embedded-video'>
            <iframe
              src={src}
              height='315'
              width='560'
            />
          </div>
        );
      }
      return (
        <div className='embedded-video'>
          <iframe
            src={src}
            height='315'
            width='560'
            sandbox='true'
          />
        </div>
      );
    }
    if (src.includes('vimeo')) {
      return (
        <div className='embedded-video'>
          <iframe
            src={src}
            height='315'
            width='560'
            sandbox='allow-scripts allow-same-origin'
          />
        </div>
      );
    }

    return (
      <video controls height='315' width='560'>
        <source src={src} />
      </video>
    );
  },
};
export default function ResourceItem({ article }: { article: IArticleDto | undefined | null }): JSX.Element {
  const sanitizedContent = useMemo(() => DOMPurify.sanitize(article?.content_raw ?? '', {
    ADD_TAGS: ['iframe', 'video'], ADD_ATTR: ['target', 'src', 'class', 'frameborder', 'allowfullscreen'],
  }), [article?.content_raw]);
  const content = useMemo(() => parse(sanitizedContent, parseVideo), [sanitizedContent]);

  if (!article) return <Redirect to='/' />;

  return (
    <Container>
      <ArticleHeader article={article} className='article-header' />
      <ArticleTitle>{article.title}</ArticleTitle>
      <ArticleDescription className='description'>
        {content}
      </ArticleDescription>

    </Container>
  );
}

const Container = styled(Card)`
  margin-bottom: 25px;
  min-width: 777px;

  img {
    max-width: 1000px;

    ${device.mobile} {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    margin: 0;
  }

  h6 {
    margin: 0;
    padding: 0;
    height: 0;
    text-align: right;
    position: relative;
    bottom: 51px;
    font-weight: 400;
  }

  .description {
    padding-top: 7px;
    padding-bottom: 7px;

    p {
      color: inherit;
    }

    a {
      strong {
        font-weight: 500;
      }
    }
  }

  .embedded-video {
    max-width: 560px; /* Maximum width */
    max-height: 315px; /* Maximum height */
    overflow: hidden;
  }

  .embedded-video iframe {
    width: 100%;
  }

  @media (max-width: 660px) {
    .embedded-video iframe {
      height: calc(100vw * (9 / 16)); /* Maintain 16:9 aspect ratio */
    }
  }

  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    .article-header {
      margin-bottom: 0;
    }

    .community-logo {
      max-width: 60px;
      height: auto;
      align-self: center;
    }
  }

  ${device.tablet} {
    min-width: 100px;
  }
`;
