import useAxios from 'axios-hooks';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IDiscussionDto } from 'api/dtos/discussion.dto';
import { ICommunity } from 'api/tables';
import { getDisplayName } from 'api/utils';
import ReplyCountIconImage from 'assets/icons/reply-count.svg';
import ReplyIconImage from 'assets/icons/reply.svg';
import CommunityLogo from 'components/CommunityLogo';
import UserAvatar from 'components/page-template/UserAvatar';
import ReadMore from 'components/ReadMore';
import { StyledLink } from 'styles/styled-components';
import Card from 'styles/styled-components/Card';
import theme, { device } from 'styles/theme';

export function DiscussionListItem({ discussion }: { discussion: IDiscussionDto }): JSX.Element {
  const { t } = useTranslation();
  const linkToDiscussion = `/discussions/${discussion.id}`;
  const { user } = discussion;
  const [community] = useAxios<ICommunity>(`/api/communities/${discussion.community_id}`);
  const replyCount = useMemo(() => {
    if (discussion.replyCount === 0) {
      return t('No replies yet');
    }
    if (discussion.replyCount === 1) {
      return t('1 reply');
    }
    return t('{{count}} replies', { count: discussion.replyCount });
  }, [discussion.replyCount, t]);

  const date = format(new Date(discussion.created_at), 'PP');
  const city = user.city ? `(${user.city})` : '';

  return (
    <DiscussionCard>
      <section className='discussion-header'>
        <div className='avatar-user'>
          <UserAvatar user={user} size={60} showMenu={false} profileLink />
          <h5><StyledLink to={`/profile/${user.id}`}>{getDisplayName(user)}</StyledLink> {city} {t('asked in')} {community.data?.name} {t('on')} {date}</h5>
        </div>
        <CommunityLogo id={community.data?.id || 0} logoUrl={community.data?.logo_url || ''} name={community.data?.name} className='community-icon' />
      </section>

      <section className='discussion-title'>
        <Link className='link' to={linkToDiscussion}><h3>{discussion.title}</h3></Link>
      </section>

      <ReadMore wordLimit={50} className='discussion-body' link={linkToDiscussion}>
        {discussion.body || ''}
      </ReadMore>

      <section className='discussion-footer'>
        <Link to={linkToDiscussion}>
          <div className='icon'>
            <img src={ReplyCountIconImage} />
            <p>{replyCount}</p>
          </div>
        </Link>

        <Link to={linkToDiscussion}>
          <div className='icon'>
            <img src={ReplyIconImage} />
            <p>{t('Read or reply')}</p>
          </div>
        </Link>
      </section>
    </DiscussionCard>
  );
}

const DiscussionCard = styled(Card)`
  margin-bottom: 50px;

  .discussion-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px;

    .avatar-user {
      display: flex;
      align-items: center;

      h5 {
        margin: 0 0 0 10px;
        text-transform: none;
        color: #BAB9B9;
        line-height: normal;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .community-icon {
      max-width: 60px;
      height: auto;
      align-self: center;
    }
  }

  .discussion-title {
    margin: 0 0 26px;

    .link {
      text-decoration: none;

      h3 {
        margin: 0;
        color: #000;
        font-family: Lora, serif;
        font-size: 23px;
        line-height: 30px;
        font-weight: 500;
      }
    }
  }

  .discussion-body {
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0;
    padding-right: 4%;
  }

  .discussion-footer {
    display: flex;

    .icon {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: 0 35px 0 0;
      text-decoration: none;

      img {
        margin: 0 3px 0 0;
      }
    }

    p {
      color: ${theme.primary};
    }
  }

  ${device.mobile} {
    .discussion-header {
      .community-icon {
        display: none;
      }
    }

    .discussion-title {
      .link {
        font-size: 18px;
      }
    }

    .description-body {
      margin-bottom: 18px;
      font-size: 18px;
      padding-right: 0%;
    }

    .discussion-footer {
      .icon {
        margin: 0 14px 0 0;
        font-size: 12px;
      }
    }
  }
`;
