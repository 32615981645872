import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Else, If, Then, When,
} from 'react-if';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LinkifyInNewTab from './LinkifyInNewTab';
import Anchor from 'styles/styled-components/Anchor';
import theme from 'styles/theme';

interface IReadMore {
  children: string;
  wordLimit: number;
  className?: string;
  showPreview?: boolean;
  cropped?: boolean;
  link?: string;
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

function sanitize(text: string): string {
  const pubMedArtifact = /;[\d\-():]+/gmi;
  const doiArtifact = /([0-9]):(?![0-9]{1,2}(am|pm|AM|PM)?)[A-z0-9\-()]+/gmi;

  const processedText = text
    .replace(pubMedArtifact, '.')
    .replace(/[A-z][0-9]+-[A-z][0-9]+/gmi, '') // Removes ons.org artifacts
    .replace(/--/gmi, '')
    .replace(/[\u2029]+ ?.?/gmi, '') // Removes line seperators
    .replace(/ \. /gmi, '')
    .replace(/ ?\.\. /gmi, '. ')
    .replace(doiArtifact, '')
    .replace(/e[0-9]+\./gmi, '') // Removes BMJ artifacts
    // why? it brokey the links... forgive me but I'm removing "discussions" from this for now
    .replace(/(METHODS?|MATERIALS?|STUDY|DESIGN|RESULTS?|CONCLUSIONS?|IMPLICATIONS?|FINDINGS?)/gmi, ' $1') // These should always have a space before them
    .replace(/\[OPEN Access] ?/gmi, '')
    .replace(/ ?&nbsp?; ?/gmi, ' ')
    .replace(/;;/gmi, ';') // Removes HTML artifacts ^^
    .replace(/&amp;*/gmi, '&')
    .replace(/&lt;*/gmi, '<')
    .replace(/&gt;*/gmi, '>')
    .trim();

  const sanitizedHtml = DOMPurify.sanitize(processedText, { USE_PROFILES: { html: true }, FORBID_TAGS: ['img'] });
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = sanitizedHtml;
  tempDiv.querySelectorAll('a').forEach((anchor) => {
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('rel', 'noopener noreferrer');
  });

  return tempDiv.innerHTML;
}

export default function ReadMore({
  children, wordLimit, className, showPreview, cropped, link, href, onClick,
}: IReadMore): JSX.Element {
  const [showMore, setShowMore] = useState<boolean>(false);
  const { t } = useTranslation();

  const text = useMemo(() => sanitize(children), [children]);
  const isOverWordLimit = wordLimit < text.split(' ').length;
  const content = useMemo(() => parse(text), [text]);
  const shortContent = useMemo(() => {
    const shortText = `${text.split(' ').slice(0, wordLimit).join(' ')}${isOverWordLimit ? '...' : ''}`;
    return parse(shortText);
  }, [isOverWordLimit, text, wordLimit]);

  const readMoreText = cropped ? `${t('View source')} »` : `${t('More')} »`;
  const readLessText = `« ${t('Less')}`;

  function handleOnClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    setShowMore(!showMore);
    if (onClick) {
      onClick(e);
    }
  }

  if (link) {
    return (
      <Container className={className}>
        <LinkifyInNewTab>
          {shortContent}
        </LinkifyInNewTab>
        <When condition={isOverWordLimit}>
          <Link to={link}>
            {readMoreText}
          </Link>
        </When>
      </Container>
    );
  }

  return (
    <Container className={className}>
      <LinkifyInNewTab>
        <If condition={showPreview === false}>
          <Then>
            {content}
          </Then>
          <Else>
            <If condition={showMore}>
              <Then>
                <span>{content}</span>
              </Then>
              <Else>
                <span>{shortContent}</span>
              </Else>
            </If>
            <When condition={isOverWordLimit}>
              <If condition={cropped}>
                <Then>
                  <a role='presentation' className='prompt' target='_blank' rel='noreferrer' href={href}>
                    {readMoreText}
                  </a>
                </Then>
                <Else>
                  <span role='presentation' className='prompt' onClick={handleOnClick}>
                    {showMore ? readLessText : readMoreText}
                  </span>
                </Else>
              </If>
            </When>
          </Else>
        </If>
      </LinkifyInNewTab>
    </Container>
  );
}

const Container = styled.div`
  text-transform: none;
  text-align: left;
  text-justify: inter-word;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 20px;
  }

  .prompt {
    ${Anchor}

    text-decoration: none;
    font-weight: 400;
    color: ${theme.primary};

    &::before {
      content: ' ';
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
