import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useNavBarContext } from './NavBarContext';
import Doc from 'assets/icons/doc.png';
import Lightbulb from 'assets/icons/lightbulb.png';
import Megaphone from 'assets/icons/megaphone.svg';
import SpeechBubble from 'assets/icons/speech-bubble.svg';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import theme, { device } from 'styles/theme';

function TopLinks({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation();
  const { isMobileMenuOpen, setMobileMenuOpen } = useNavBarContext();
  const { communities, user } = useLoggedInUser();
  const isMembershipContentAvailable = communities.some((community) => community.user.relationship === 'MEMBER') && user.unsubscribed_at === null;
  const resourcesEnabled = isMembershipContentAvailable && communities.some((community) => community.hasResources && community.user.relationship === 'MEMBER');

  function onClick(): void {
    if (isMobileMenuOpen) setMobileMenuOpen(false);
  }

  return (
    <Container className={className}>
      <NavLink onClick={onClick} className='link' activeClassName='active' to='/articles'><img src={Doc} />{t('Articles')}</NavLink>
      <NavLink onClick={onClick} className='link' activeClassName='active' to='/news'><img src={Megaphone} />{t('News')}</NavLink>
      <When condition={resourcesEnabled}>
        <NavLink onClick={onClick} className='link' activeClassName='active' to='/resources'><img src={Lightbulb} />{t('Resources')}</NavLink>
      </When>
      <When condition={isMembershipContentAvailable}>
        <NavLink onClick={onClick} className='link' activeClassName='active' to='/discussions'><img src={SpeechBubble} />{t('Q&A')}</NavLink>
      </When>
    </Container>
  );
}

export default TopLinks;

const Container = styled.div`
  .link {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 47px;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    color: ${theme.primary} !important;

    img {
      margin-right: 17px;
      max-height: 23px;
    }

    &:hover {
      cursor: pointer;
      background-color: white;
    }

    &.active {
      background-color: white;
    }
  }

  ${device.tablet} {
    width: 140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .link {
      padding-left: 0;

      &.active,
      &:hover {
        background-color: transparent;
      }
    }
  };

`;
