import { Trans, useTranslation } from 'react-i18next';

import Discussions from 'components/Discussions';
import TabNavigator from 'components/TabNavigator';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { StyledLink } from 'styles/styled-components';
import { getMarketingUrl } from 'utils';

export default function DiscussionsTabs(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useLoggedInUser();

  const tabs = [
    {
      label: t('All Q&A'),
      component: <Discussions
        emptyTabHeader={t('There are no discussions currently available to you.')}
        emptyTabMessage={(
          <Trans>
            You may not have access to the discussions because you are a follower
            {' '}<StyledLink to='/follow'>(view and change your communities).</StyledLink>
            {' '}Or, there may be no questions posted in your communities.
          </Trans>
      )}
      />,
    },
    {
      label: t('My Q&A'),
      component: <Discussions
        emptyTabHeader={t('You have not posted any questions yet.')}
        emptyTabMessage={(
          <Trans>
            <StyledLink to='/discussions/new'>Post a question</StyledLink>
            {' '}or learn more about <a target='_blank' href={`${getMarketingUrl()}/faq-1`} rel='noreferrer'>how Q&A on Sosido works.</a>
          </Trans>
)}
        userId={user.id}
      />,
    },
  ];

  return (
    <TabNavigator tabs={tabs} />
  );
}
