import { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { FieldValues, FormState } from 'react-hook-form';

interface IAsyncFormButton<T extends FieldValues> extends ButtonHTMLAttributes<HTMLButtonElement> {
  formState: FormState<T>;
  onClick?: never;
}

interface IAsyncClickButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  formState?: never;
  onClick(): Promise<void>;
}

type IAsyncButton<T extends FieldValues> = (IAsyncFormButton<T> | IAsyncClickButton) & { children: string | string[], resetAfterSubmit?: boolean };

export function AsyncButton<T extends FieldValues>({
  formState, children, resetAfterSubmit = false, onClick, ...props
}: IAsyncButton<T>): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (formState) {
      const isSubmitted = formState.isSubmitSuccessful && formState.submitCount !== 0;
      setLoading(formState.isSubmitting || (!resetAfterSubmit && isSubmitted));
    }
  }, [formState, onClick, resetAfterSubmit]);

  async function handleAsyncClick(): Promise<void> {
    if (!onClick) return;
    try {
      setLoading(true);
      await onClick();
    } finally {
      if (resetAfterSubmit) setLoading(false);
    }
  }

  return (
    <button onClick={handleAsyncClick} {...props} disabled={isLoading || props.disabled}>
      {children}
    </button>
  );
}
