import axios from 'axios';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import ArticleHeader from './ArticleHeader';
import { IArticleDto } from 'api/dtos/article.dto';
import { IUserDto } from 'api/dtos/user.dto';
import {
  cleanTitle, getDisplayName,
} from 'api/utils';
import ArticleIcon from 'assets/icons/article.svg';
import OpenAccessIcon from 'assets/icons/open-access.svg';
import ShareIcon from 'assets/icons/share.svg';
import ReadMore from 'components/ReadMore';
import ShareModal from 'components/ShareModal';
import { StyledLink } from 'styles/styled-components';
import ArticleDescription from 'styles/styled-components/ArticleDescription';
import ArticleTitle from 'styles/styled-components/ArticleTitle';
import theme, { device } from 'styles/theme';

interface IArticle {
  article: IArticleDto | undefined | null;
  showPreview?: boolean;
}

export default function Article({ article, showPreview }: IArticle): JSX.Element {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [users] = useAxios<IUserDto[]>(`/api/articles/${article!.id}/members`);

  const isArticlePage = window.location.pathname.includes('/articles/');
  const url = `${document.location.origin}/articles/${article?.id}`;
  // The timeout is here because emails are not tracked properly otherwise.
  const trackPageView = (): void => {
    if (!isArticlePage && article) {
      setTimeout(() => {
        ReactGA.send({
          hitType: 'pageview',
          page: `/articles/${article.id}`,
          title: document.title,
        });
      }, 2000);
    }
  };
  const [shortUrl, setShortUrl] = useState('');
  const handleShareClick = async () => {
    if (!url || !article) return;
    try {
      const shortened = await axios.post<string>(`/api/link/shorten/article/${article.id}`);
      setShortUrl(shortened.data);
      setIsOpen(true);
    } catch (e) {
      console.error('Failed to shorten URL', e);
    }
  };

  useEffect(() => {
    if (isArticlePage && article) {
      setTimeout(() => {
        ReactGA.send({
          hitType: 'pageview',
          page: `/articles/${article.id}`,
          title: document.title,
        });
      }, 2000);
    }
  }, []);

  if (!article) {
    return (
      <Redirect to='/' />
    );
  }
  return (
    <Container>
      <ArticleHeader article={article} />
      <a href={article.url} target='_blank' rel='noopener noreferrer' onClick={trackPageView}>
        <ArticleTitle>{cleanTitle(article.title)}</ArticleTitle>
      </a>

      <p className='authors'>
        <ArticleAuthors authors={article.authors} members={users.data} linkedAuthors={article.linked_authors} trackPageView={trackPageView} />
      </p>
      <ArticleDescription>
        <ReadMore wordLimit={40} className='description' href={article.url} showPreview={showPreview} cropped={article.cropped} onClick={trackPageView}>
          {article.content || article.description || ''}
        </ReadMore>
      </ArticleDescription>

      <section className='bottom'>
        <If condition={!!article.open_access}>
          <Then>
            <a className='link article-icon' target='_blank' href={article.url} rel='noopener noreferrer' onClick={trackPageView}>
              <img src={OpenAccessIcon} className='open-access-icon' />
              <p>{t('Open access')}</p>
            </a>
          </Then>
          <Else>
            <a className='link article-icon' target='_blank' href={article.url} rel='noopener noreferrer' onClick={trackPageView}>
              <img src={ArticleIcon} />
              <p>{t('Full text link')}</p>
            </a>
          </Else>
        </If>
        <div className='article-icon' onClick={handleShareClick}>
          <img src={ShareIcon} />
          <p>{t('Share')}</p>
        </div>
      </section>
      <ShareModal
        title={article.title}
        url={shortUrl}
        isOpen={modalIsOpen}
        setIsOpen={setIsOpen}
      />
    </Container>
  );
}

interface IArticleAuthors {
  authors: string[];
  members?: IUserDto[];
  linkedAuthors?: { user_id: number, position: number }[];
  trackPageView: () => void;
}

function ArticleAuthors({
  authors, members, linkedAuthors, trackPageView,
}: IArticleAuthors): JSX.Element {
  if (authors && members && linkedAuthors) {
    if (authors.length < 1) return <></>;
    const linkedAuthorsMap = new Map(linkedAuthors.map((i) => [i.position, i.user_id]));
    return authors.map((author, index) => {
      const userId = linkedAuthorsMap.get(index) as number;
      if (userId) {
        const userIndex = members.findIndex((member) => member.id === userId);
        if (userIndex !== -1 && members[userIndex]) {
          return (
            <StyledLink
              to={`/profile/${userId}`}
              onClick={trackPageView}
            >
              {getDisplayName(members[userIndex])}
            </StyledLink>
          );
        }
      }

      return <>{author}</>;
    }).reduce((prev, curr) => <>{prev}{', '}{curr}</>);
  }
  return <></>;
}

const Container = styled.div`
  margin-bottom: 25px;

  .not-found {
    margin: auto;
  }

  .description {
    margin-bottom: 35px;
  }

  .authors {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 25px;
    color: #BAB9B9;
    padding-right: 4%;
  }

  .bottom {
    display: flex;
    align-items: center;

    .link {
      text-decoration: none;
      font-weight: 400;
      position: relative;
    }

    img {
      width: 17px;
      height: auto;
      margin-right: 5px;
    }

    .open-access-icon {
      height: 17px;
      width: 17px;
    }

    .article-icon {
      display: flex;

      &:hover {
        cursor: pointer;
      }

      &:first-of-type {
        margin-right: 26px;
      }

      p {
        color: ${theme.primary};
      }
    }
  }

  ${device.tablet} {
    a {
      h3 {
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 15px;
        margin-top: -15px;
      }
    }

    .description {
      margin-bottom: 18px;
    }

    .authors {
      margin-bottom: 15px;
      font-size: 16px;
      padding-right: 0%;
    }
  }

  ${device.mobile} {
    margin-bottom: 5px;
    zoom: 0.95;

    a {
      h3 {
        margin-top: 15px;
      }
    }

    .bottom {
      margin-bottom: 0;
    }

    .description {
      font-size: 16px;
    }
  }
`;
