/* eslint-disable @typescript-eslint/indent */
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { SchemaOf, object, string } from 'yup';

import { AsyncButton } from 'components/form/AsyncButton';
import Input from 'components/form/Input';
import ValidationErrors from 'components/form/ValidationErrors';
import theme from 'styles/theme';

interface IUpdatePasswordModal {
  isOpen: boolean;
  onRequestClose(): void;
  closeModal(): void;
}

interface IUpdatePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema: SchemaOf<IUpdatePasswordForm> = object({
  currentPassword: string().required('Current password required'),
  newPassword: string().required()
    .test(
      'is-same',
      'Passwords must match',
      (value, context) => value === (context.parent as IUpdatePasswordForm).confirmPassword,
    ),
  confirmPassword: string().required()
    .test(
      'is-same',
      'Passwords must match',
      (value, context) => value === (context.parent as IUpdatePasswordForm).newPassword,
    ),
});

export default function UpdatePasswordModal({ isOpen, onRequestClose, closeModal }: IUpdatePasswordModal): JSX.Element {
  const formMethods = useForm<IUpdatePasswordForm>({
    resolver: yupResolver(schema),
  });
  const {
    register, handleSubmit, formState, reset, setError,
  } = formMethods;
  const { t } = useTranslation();

  async function onSubmit(passwordData: IUpdatePasswordForm): Promise<void> {
    const passwordUpdate = {
      currentPassword: passwordData.currentPassword,
      updatedPassword: passwordData.confirmPassword,
    };

    try {
      await axios.post(
        '/api/auth/password/change',
        passwordUpdate,
      );
      toast.success(t('Successfully updated password'));
      reset();
    } catch (e) {
      setError('currentPassword', {
        type: 'manual',
        message: 'Incorrect password',
      });
      toast.error(t('Incorrect current password'));
      console.error(e);
    }

    closeModal();
  }

  const rule = { required: ValidationErrors.REQUIRED };

  return (
    <FormProvider {...formMethods}>
      <Modal id='change-password-modal' ariaHideApp={false} isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Input className='current-password' type='password' label={t('Current password')} {...register('currentPassword', rule)} />
          <Input className='new-password' type='password' label={t('New password')} {...register('newPassword', rule)} />
          <Input className='confirm-password' type='password' label={t('Confirm new password')} {...register('confirmPassword', rule)} />
          <Link to='/password-reset' className='forgot-password'>{t('Forgot password')}</Link>
          <div className='button-container'>
            <AsyncButton resetAfterSubmit formState={formState} type='submit' className='save'>{t('Save')}</AsyncButton>
            <button className='cancel' onClick={closeModal}>{t('Cancel')}</button>
          </div>
        </StyledForm>
      </Modal>
    </FormProvider>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .current-password,
  .new-password,
  .confirm-password {
    width: 90%;
    padding-top: 15px;

    label {
      color: black;
    }
  }

  .forgot-password {
    margin-top: 15px;
    font-weight: 400;
    font-size: 14px;
    padding-right: 63%;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .save {
      height: 51px;
      width: 195px;
      padding: 2px 0 0;
      margin-right: 10px;
    }

    .cancel {
      background: white;
      color: black;
      border: 3px solid ${theme.primary};
      height: 51px;
      width: 195px;
      padding: 2px 0 0;
      margin-left: 10px;
    }
  }
`;

const modalStyles = {
    overlay: {
      backgroundColor: 'none',
    },

    content: {
      width: '484px',
      height: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 14px 54px rgb(112 112 112 / 35%)',
    },
};
