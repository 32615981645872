import { captureException } from '@sentry/react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ErrorPage from './ErrorPage';
import PageTemplate from '../page-template/PageTemplate';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  const { t } = useTranslation();

  captureException(error);

  return (
    <PageTemplate>
      <Container>
        <h2>{t('Oops! Something went wrong.')}</h2>
        <p>{error.message}</p>
        <button onClick={() => resetErrorBoundary()}>
          {t('Try again')}
        </button>
      </Container>
    </PageTemplate>
  );
}

export default ErrorFallback;

const Container = styled(ErrorPage)`
  h2 {
    font-weight: 700;
  }

  p {
    margin-bottom: 20px;
  }

  button {
    padding: 20px;
  }
`;
