import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ICommunity } from 'api/tables';
import { AxiosWrapper } from 'components/AxiosWrapper';
import Discussions from 'components/Discussions';
import Publications from 'components/Publications';
import TabNavigator from 'components/TabNavigator';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import Resources from 'resources/Resources';

function CommunityTabs(): JSX.Element {
  const { slug } = useParams<{ slug: string }>();
  const { userState: { communities, user } } = useGlobalUserContext();
  const [community] = useAxios<ICommunity>(`/api/communities/slug/${slug}`);
  const { t } = useTranslation();
  const isMembershipContentAvailable = communities.some(
    (c) => c.slug === slug && c.user.relationship === 'MEMBER' && user?.unsubscribed_at === null,
  );
  const resourcesEnabled = isMembershipContentAvailable && communities.some(
    (c) => c.slug === slug && c.hasResources,
  );

  const tabs = (communityId: number) => {
    const tabList = [
      {
        label: t('Member Publications'),
        component: <Publications emptyTabHeader={t('No community member publications have been posted yet.')} communityId={communityId} />,
      },
      {
        label: t('News'),
        component: <Publications emptyTabHeader={t('No community news have been posted yet.')} communityId={communityId} path='news' />,
      },
    ];

    if (isMembershipContentAvailable) {
      // inject 'Q&A' between 'Member Publications' and 'News'
      tabList.splice(1, 0, {
        label: t('Q&A'),
        component: <Discussions emptyTabHeader={t('No community Q&As have been posted yet.')} communityId={communityId} />,
      });
      if (resourcesEnabled) {
        // inject 'Resources' after 'News'
        tabList.splice(3, 0, {
          label: t('Resources'),
          component: <Resources communityId={communityId} />,
        });
      }
    }

    return tabList;
  };

  return (
    <AxiosWrapper requests={[community]}>
      <TabNavigator tabs={community.data?.id ? tabs(community.data?.id) : []} />
    </AxiosWrapper>
  );
}

export default CommunityTabs;
