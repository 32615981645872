import {
  cleanEnv,
  str,
  url,
} from 'envalid';

export const env = cleanEnv({
  REACT_APP_PROXY: process.env.REACT_APP_PROXY,
  REACT_APP_MARKETING_URL: process.env.REACT_APP_MARKETING_URL,
  REACT_APP_GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
}, {
  REACT_APP_PROXY: url({}),
  REACT_APP_MARKETING_URL: url({}),
  REACT_APP_GA_TRACKING_ID: str({}),
  REACT_APP_SENTRY_DSN: url({}),
  REACT_APP_ENV: str({}),
});
