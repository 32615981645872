import axios, { AxiosResponse } from 'axios';
import { EditorState, RawDraftContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import EditorFileDrop from './EditorFileDrop';
import { INewDiscussionWithAttachmentIdsDto } from 'api/dtos/discussion.dto';
import { IFile } from 'api/tables';
import { isMember } from 'api/utils';
import { AsyncButton } from 'components/form/AsyncButton';
import ControlledSelect from 'components/form/ControlledSelect';
import TextArea from 'components/form/TextArea';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { StyledLink } from 'styles/styled-components';
import { device } from 'styles/theme';

interface ICommunityOptionsType {
  value: string;
  label: string;
}

interface ISubmitDataType {
  title: string;
  community_id: string;
}

interface INewDiscussionFormType extends ISubmitDataType {
  body: string;
  user_id: number;
  attachmentIds: string[];
}

// users usually copy paste text from word or other programs which adds a lot of
// inline styles that deviate from figma. This will remove all external inline styles
export function removeInlineStyles(editorState: EditorState): string {
  const htmlString = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
  const htmlNode = document.createElement('div');
  htmlNode.innerHTML = htmlString;
  htmlNode.querySelectorAll('*').forEach((node) => {
    node.removeAttribute('style');
  });
  return htmlNode.innerHTML;
}

export default function NewDiscussion(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, communities } = useLoggedInUser();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [attachments, setAttachments] = useState<IFile[]>([]);
  const {
    register, handleSubmit, control, formState,
  } = useForm<INewDiscussionFormType>();

  const communityOptions: ICommunityOptionsType[] = communities.filter(isMember)
    .map((community) => ({
      value: community.id.toString(),
      label: community.name,
    }));

  async function onSubmit(submitData: ISubmitDataType): Promise<void> {
    const { title } = submitData;
    const { community_id } = submitData;

    if (!community_id) {
      toast.error(t('Please select a community.'));
      return;
    }

    if (!title) {
      toast.error(t('Please enter a title.'));
      return;
    }

    try {
      await axios.post<INewDiscussionWithAttachmentIdsDto, AxiosResponse>(
        `/api/discussions/communities/${community_id}`,
        {
          newDiscussion: {
            body: removeInlineStyles(editorState),
            title,
            community_id,
            user_id: user.id,
          },
          attachments,
        },
      );
      toast.success(t('Your question has been posted'));
      history.push('/discussions');
    } catch (e) {
      toast.error(t('Error Publishing Question'));
      console.error(e);
    }
  }

  function ignoreEnterKey(e: React.KeyboardEvent<HTMLDivElement>): void {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className='discussion-info-message'>
          You must be a member of a community on Sosido to ask, answer or view the Q&A.
          {' '}<StyledLink to='/follow'>View and change your communities.</StyledLink>
        </p>
        <div className='discussion-community' onKeyDown={(e) => ignoreEnterKey(e)}>
          <label className='input-label-text'>{t('Which community would you like to ask your question to?')}</label>
          <ControlledSelect
            control={control}
            name='community_id'
            options={communityOptions}
            placeholder={t('Select a community which you are a member of.')}
            noOptionsMessage="You're not currently a member of any communities on Sosido."
          />
        </div>
        <TextArea
          className='discussion-title'
          type='text'
          label={t('What is your question?')}
          placeholder={`${t('Ask your question here')}...`}
          control={control}
          maxLength={500}
          {...register('title', {})}
        />
        <div className='discussion-description'>
          <label className='input-label-text'>{t('Additional details (optional)')}</label>
          <EditorFileDrop
            attachments={attachments}
            setAttachments={setAttachments}
            editorState={editorState}
            setEditorState={setEditorState}
            placeholder={t('Please provide your colleagues with any details or background that would help them answer your question.')}
            type='new-discussion'
          />
        </div>
        <div className='submit-cancel'>
          <AsyncButton resetAfterSubmit value='Submit Question' type='submit' formState={formState}>{t('Submit Question')}</AsyncButton>
          <button className='cancel' type='button' onClick={() => history.goBack()}>{t('Cancel')}</button>
        </div>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 656px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  overflow-x: hidden;

  textarea:focus,
  textarea:active {
    outline: none;
    border-color: black;
    outline-color: black;
  }

  .discussion-title,
  .discussion-community {
    margin: 0 0 35px;
  }

  .discussion-info-message {
    margin-bottom: 20px;
    line-height: initial;
  }

  .discussion-description {
    margin: 0 0 20px;
    position: relative;

    .file-name {
      padding-top: 2px;
      position: relative;
      right: 5px;
    }
  }

  label {
    font-size: 18px;
    color: #000;
    margin: 0 0 11px;
    text-transform: None;
  }

  .submit-cancel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 67px;
    width: 110%;

    button.cancel {
      text-transform: None;
      background-color: transparent;
      color: #000;
      box-shadow: none;
      position: relative;
      right: 5px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  ${device.mobile} {
    textarea {
      width: calc(100vw - 50px);
    }
  }
`;
