import useAxios from 'axios-hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import styled from 'styled-components';

import { AxiosWrapper } from 'components/AxiosWrapper';
import { AsyncButton } from 'components/form/AsyncButton';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';

function UnsubscribePage(): JSX.Element {
  const { t } = useTranslation();
  const { userStateDispatch, userState: { user } } = useGlobalUserContext();
  const [unsubscribe, startUnsubscribe] = useAxios<string>({
    url: `/api/users/${user?.id}/unsubscribe`,
    method: 'PUT',
  }, { manual: true });

  async function handleUnsubscribe(): Promise<void> {
    await startUnsubscribe();
    userStateDispatch({ type: 'logout' });
  }

  return (
    <AxiosWrapper requests={[unsubscribe]}>
      <Container>
        <If condition={!user}>
          <Then>
            <h4>{t('You have successfully unsubscribed.')}</h4>
            <Trans>
              <p>
                If you would like to reactivate your Sosido account in the future,
                {' '}please sign up at <a href='https://app.sosido.com/signup'>www.sosido.com.</a>
              </p>
            </Trans>
          </Then>
          <Else>
            <h4>{t('You have requested that {{emailAddress}} be unsubscribed from Sosido.', { emailAddress: user?.email })}</h4>
            <Trans>
              <p>Once unsubscribed, this account will be deactivated. The user will no longer:</p>
              <ul>
                <li>have their publications tracked/featured for their communities</li>
                <li>have access to their community&apos;s Q&A, or</li>
                <li>receive any emails from Sosido.</li>
              </ul>
            </Trans>
            <AsyncButton onClick={handleUnsubscribe}>Yes, unsubscribe me from Sosido</AsyncButton>
          </Else>
        </If>
      </Container>
    </AxiosWrapper>
  );
}

export default UnsubscribePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ul {
    list-style-position: inside;
    font-weight: 400;
    font-size: 14px;
    padding-left: 10px;
  }

  p,
  ul li {
    line-height: 20px;
  }

  h4 {
    text-transform: none;
    margin-bottom: 20px;
  }

  button {
    width: 350px;
    margin-top: 25px;
  }
`;
