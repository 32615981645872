import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import RootContainer from './components/RootContainer';
import theme from './styles/theme';
import ErrorFallback from 'components/error/ErrorFallback';
import UserContextProvider from 'contexts/user-context/UserContextProvider';
import GlobalStyle from 'styles/GlobalStyle';

const helmetContext = {};
function App(): JSX.Element {
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <UserContextProvider>
              <RootContainer />
            </UserContextProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
