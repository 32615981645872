import useAxios from 'axios-hooks';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CommunityFeeds from './components/CommunityFeeds';
import FeedDictionary from './components/FeedDictionary';
import { FeedsPageContext } from './FeedsPageContext';
import { IFeed } from 'api/tables';
import { AxiosWrapper } from 'components/AxiosWrapper';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { StyledLink } from 'styles/styled-components';
import { device } from 'styles/theme';

export default function FeedsPage(): JSX.Element {
  const { t } = useTranslation();
  const [initialUserFeeds] = useAxios<IFeed[]>('/api/feeds', { useCache: false });
  const [userFeeds, setUserFeeds] = useState<IFeed[]>([]);
  const { communities } = useLoggedInUser();

  useEffect(() => {
    if (!initialUserFeeds.data) return;
    setUserFeeds(initialUserFeeds.data);
  }, [initialUserFeeds.data, setUserFeeds]);

  const communityFeeds = useMemo(() => {
    const feedListElements = communities.map((community) => <CommunityFeeds community={community} key={community.id} />);
    return feedListElements;
  }, [communities]);
  const feedPageContext = useMemo(() => ({ userFeeds, setUserFeeds }), [userFeeds]);

  return (
    <AxiosWrapper requests={[initialUserFeeds]}>
      <FeedsPageContext.Provider value={feedPageContext}>
        <Container>
          <h3>{t('My Journal Sources')}</h3>
          <p>
            <Trans>
              Sosido sends you a personalized email digest with updates from your communities and abstracts from your journal sources, once per week.
              Customize your journal sources below, or <StyledLink to='/follow'>follow other communities on Sosido.</StyledLink>
            </Trans>
          </p>
          {communityFeeds}
          <FeedDictionary className='dictionary' />
        </Container>
      </FeedsPageContext.Provider>
    </AxiosWrapper>
  );
}

const Container = styled.div`
  h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
  }

  p {
    margin-bottom: 62px;
    font-size: 18px;
    text-transform: none;

    a {
      color: #0038FF !important;
    }
  }

  .dictionary {
    margin: 118px 0 25px;
  }

  ${device.mobile} {
    p {
      margin-bottom: 54px;
    }

    .dictionary {
      margin: 77px 0 19px;
    }
  }
`;
